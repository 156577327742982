import React from 'react';

import ReceiptItem from './ReceiptItem';
import Divider from '../../../components/Divider';

export default function ChooseReceiptItems({
  receipt,
  submitSelection,
  selectedItemIDs,
  selectItem,
  currentUID,
}) {
  // UI for choosing the item to split
  const itemsJsx = [];
  for (let i = 0; i < receipt.items.length; i++) {
    const item = receipt.items[i];
    const itemName = item.name;
    const itemPrice = item.price;

    const itemMembers = [];
    receipt.member_to_item_ids[currentUID] = selectedItemIDs || []; // eslint-disable-line
    const allMembers = Object.keys(receipt.member_to_item_ids);
    for (let j = 0; j < allMembers.length; j++) {
      const memberUID = allMembers[j];
      const itemIDArray = receipt.member_to_item_ids[memberUID] || [];
      if (itemIDArray.includes(item.id)) {
        itemMembers.push(memberUID);
      }
    }

    itemMembers.sort();
    if (itemMembers.includes(currentUID)) {
      const index = itemMembers.indexOf(currentUID);
      itemMembers.splice(index, 1);
      itemMembers.unshift(currentUID);
    }

    const itemMembersJsx = [];
    for (let j = 0; j < itemMembers.length; j++) {
      const memberUID = itemMembers[j];
      const memberClassName = currentUID === memberUID ? 'receiptScreen-currentMember' : 'receiptScreen-otherMember';
      const memberNameText = currentUID === memberUID ? 'You' : receipt.uid_to_name[memberUID] || 'NameError';
      itemMembersJsx.push(
        <div key={memberUID} className={memberClassName}>
          {memberNameText}
        </div>,
      );
    }

    const currentlySelected = itemMembers.includes(currentUID);
    itemsJsx.push(
      <div
        className={
          currentlySelected
            ? 'receiptScreen-currentItemContainer'
            : 'receiptScreen-otherItemContainer'
        }
        onClick={() => selectItem(item.id)}
      >
        <ReceiptItem name={itemName} price={itemPrice} />
        <div className="receiptScreen-membersContainer">{itemMembersJsx}</div>
      </div>,
    );

    itemsJsx.push(<Divider color="#F2F1F6" height={2} />);
  }

  return (
    <div>
      <div className="receiptScreen-instructionsContainer">
        <div className="receiptScreen-instructionsText">
          Select your items and press submit below.
        </div>
      </div>
      <Divider color="#F2F1F6" height={2} />
      <div className="receiptScreen-itemsContainer">{itemsJsx}</div>
      <div style={{ margin: 30, marginTop: 20 }}>
        <button className="receiptScreen-ctaButton" onClick={() => submitSelection()}>
          Submit
        </button>
      </div>
    </div>
  );
}
