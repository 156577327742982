import React from 'react';

export default function SelectItem({ name, incrementQuantity }) {
  return (
    <div
      className="demoScreen-itemSelect"
      onClick={() => {
        incrementQuantity();
      }}
    >
      <div className="demoScreen-itemSelectTitleContainer">{name}</div>
    </div>
  );
}
