import React from 'react';

import logo from '../../images/logos/stitch_light.png';

export default function ComingSoonScreen() {
  return (
    <div className="comingSoonScreen-container">
      <img src={logo} className="comingSoonScreen-title" />
      <div className="comingSoonScreen-body">Coming Soon</div>
    </div>
  );
}
