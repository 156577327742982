import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import firebase from 'firebase/compat/app';
import amplitude from 'amplitude-js';
import App from './App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: 'AIzaSyCkuxdZ81ZT0Ifw1OS8yrfTKf2pzjn5Xs0',
  authDomain: 'stitch-c9ff8.firebaseapp.com',
  databaseURL: 'https://stitch-c9ff8.firebaseio.com',
  projectId: 'stitch-c9ff8',
  storageBucket: 'stitch-c9ff8.appspot.com',
  messagingSenderId: '211764178639',
  appId: '1:211764178639:web:1e51264e2201c1fa4991ba',
  measurementId: 'G-286TLJP2KZ',
};
firebase.initializeApp(firebaseConfig);

amplitude.getInstance().init('a71fd9f44f7f9093c3220f9363be28a5');

// eslint-disable-next-line
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
