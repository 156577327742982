import './assets/css/main.min.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ReceiptScreen from './screens/receiptScreen/ReceiptScreen';
import ComingSoonScreen from './screens/homeScreen/ComingSoonScreen';
import UserLandingScreen from './screens/homeScreen/UserLandingScreen';
import MerchantLandingScreen from './screens/homeScreen/MerchantLandingScreen';
import HelpScreen from './screens/homeScreen/HelpScreen';
import StaticDeviceScreen from './screens/receiptScreen/StaticDeviceScreen';
import VendorDemoScreen from './screens/vendorDemoScreen/VendorDemoScreen';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/:receiptID" element={<ReceiptScreen />} />
          <Route path="/r/:receiptID" element={<ReceiptScreen />} />
          <Route path="/" element={<ComingSoonScreen />} />
          <Route path="/users" element={<UserLandingScreen />} />
          <Route path="/merchants" element={<MerchantLandingScreen />} />
          <Route path="/help" element={<HelpScreen />} />
          <Route path="/static-device/:deviceID" element={<StaticDeviceScreen />} />
          <Route path="/demo" element={<VendorDemoScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
