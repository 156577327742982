import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

import MenuTopBar from '../../components/MenuTopBar';
import FeatureDetail from '../../components/FeatureDetail';

import ReceiptFeedMockup from '../../images/mockups/receipt_feed_mockup.png';
import ReceiptDetailMockup from '../../images/mockups/receipt_detail_mockup.png';
import UserFeatureDetailOne from '../../images/mockups/user_feature_detail_one.png';
import UserFeatureDetailTwo from '../../images/mockups/user_feature_detail_two.png';
import UserFeatureDetailThree from '../../images/mockups/user_feature_detail_three.png';
import EnvironmentImage from '../../images/mockups/environment.png';
import DownloadAppStore from '../../images/download_app_store.png';
import DownloadGooglePlay from '../../images/download_google_play.png';

export default function UserLandingScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.getInstance().logEvent('landing_page_open', { screen: 'User' });
  }, []);

  function downloadAppClick(store) {
    amplitude.getInstance().logEvent('download_app_press', { source: 'landing_page', store });
  }

  const downloadAppJsx = (
    <div>
      <a
        href="https://apps.apple.com/us/app/stitch-split-bill/id1632674016"
        onClick={() => downloadAppClick('apple')}
      >
        <img
          src={DownloadAppStore}
          className="landingScreen-downloadButton"
          style={{ marginRight: 15 }}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.stitchfinance.stitchapp"
        onClick={() => downloadAppClick('google')}
      >
        <img src={DownloadGooglePlay} className="landingScreen-downloadButton" />
      </a>
    </div>
  );

  return (
    <div className="landingScreen-container">
      <MenuTopBar />
      <div className="landingScreen-header">
        <div className="landingScreen-headerBackground" />
        <div className="landingScreen-headerContentContainer">
          <div className="landingScreen-headerTextBlock">
            <div className="landingScreen-headerTitle">All Your Receipts in One Place</div>
            <div className="landingScreen-headerText">
              Revolutionize your personal finance experience w/ secure digital receipts.
            </div>
            {downloadAppJsx}
          </div>
          <div className="landingScreen-mockupsContainer">
            <div className="landingScreen-mockupContainer">
              <img src={ReceiptFeedMockup} className="landingScreen-mockup" />
            </div>
            <div className="landingScreen-mockupContainer">
              <img src={ReceiptDetailMockup} className="landingScreen-mockupOffset" />
            </div>
          </div>
        </div>
      </div>
      <div className="landingScreen-featuresContainer">
        <FeatureDetail
          img={UserFeatureDetailOne}
          title="All receipts in one app"
          text="Product returns, splitting bills, reimbursements, much more."
        />
        <FeatureDetail
          img={UserFeatureDetailTwo}
          title="Email integration"
          text="Connect your Gmail account to seamlessly add digital receipts."
        />
        <FeatureDetail
          img={UserFeatureDetailThree}
          title="Personalized coupons"
          text="Personalized and easy to use discounts at places you love."
        />
      </div>
      <div className="landingScreen-environmentContainer">
        <div className="landingScreen-environmentTitle">Save the Environment</div>
        <div className="landingScreen-environmentContentContainer">
          <div className="landingScreen-environmentFacts">
            <p className="landingScreen-environmentBodyText">Every year, paper receipts...</p>
            <ul className="landingScreen-environmentFactsList">
              <li>
                Kill
                {' '}
                <b>10 million trees</b>
              </li>
              <li>
                Use
                {' '}
                <b>21 billion gallons of water</b>
              </li>
              <li>
                Create
                {' '}
                <b>700 million pounds of waste</b>
              </li>
              <li>
                Emit
                {' '}
                <b>12 billion pounds of CO2</b>
              </li>
            </ul>
          </div>
          <img src={EnvironmentImage} className="landingScreen-environmentImage" />
        </div>
        <div className="landingScreen-environmentBodyText">
          With Stitch, you can help
          {' '}
          <b>save the planet</b>
          {' '}
          one receipt at a time.
        </div>
      </div>
      <div className="landingScreen-footer">
        <div className="landingScreen-footerTitle">Say goodbye to paper receipts</div>
        <div className="landingScreen-footerSubtitle">Download the app</div>
        <div className="landingScreen-footerEmail">{downloadAppJsx}</div>

        <div className="landingScreen-legalContainer">
          <div className="landingScreen-copyright">© 2022 Stitch Finance Inc.</div>
          <div className="landingScreen-innerLegalContainer">
            <button
              className="landingScreen-legalLinks"
              onClick={() => navigate('/help', { replace: false })}
            >
              contact us
            </button>
            <a className="landingScreen-legalLinks" href="/terms.pdf">
              terms
            </a>
            <a className="landingScreen-legalLinks" href="/privacypolicy.pdf">
              privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
