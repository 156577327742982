const items = [
  {
    name: 'Chicken Satay',
    price: 795,
  },
  {
    name: 'Vegetable Spring Rolls',
    price: 695,
  },
  {
    name: 'Crispy Tofu',
    price: 695,
  },
  {
    name: 'Chicken Fried Rice',
    price: 1995,
  },
  {
    name: 'Vegetable Fried Rice',
    price: 1665,
  },
  {
    name: 'Pad Thai Noodles',
    price: 1595,
  },
  {
    name: 'Seafood Noodles (Lad Na Tay)',
    price: 1695,
  },
  {
    name: 'Drunken Noodle (Pad Kee Maw)',
    price: 1595,
  },
  {
    name: 'Pad See Ew',
    price: 1595,
  },
  {
    name: 'Klew Soi',
    price: 1695,
  },
  {
    name: 'Red Curry',
    price: 1495,
  },
  {
    name: 'Masaman Curry',
    price: 1495,
  },
  {
    name: 'Panang Curry',
    price: 1495,
  },
  {
    name: 'Spicy Catfish',
    price: 1995,
  },
  {
    name: 'Spicy Roasted Duck',
    price: 1895,
  },
  {
    name: 'Sticky Rice with Mango',
    price: 795,
  },
];

export default items;
