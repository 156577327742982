import React from 'react';

import Divider from '../../../components/Divider';
import ReceiptHeader from './ReceiptHeader';
import ReceiptItem from './ReceiptItem';
import DownloadAppFooter from './DownloadAppFooter';

export default function ReceiptDetails({ receipt, viewSplitReceipt }) {
  let subtotal = 0;
  for (let i = 0; i < receipt.items.length; i++) {
    subtotal += receipt.items[i].price;
  }
  const tipPercent = receipt.tip / subtotal;
  const total = subtotal + receipt.tip + receipt.tax;

  const roundedSubtotal = Math.round(subtotal * 100) / 100;
  const roundedTax = Math.round(receipt.tax * 100) / 100;
  const roundedTipPercent = Math.round(tipPercent * 100);
  const roundedTip = Math.round(receipt.tip * 100) / 100;
  const roundedTotal = Math.round(total * 100) / 100;

  return (
    <div>
      <ReceiptHeader receipt={receipt} />

      <div className="receiptScreen-itemsContainer" style={{ marginTop: '10px' }}>
        {receipt.items.map((item, index) => {
          const lastItem = index === receipt.items.length - 1;
          return (
            <ReceiptItem
              name={item.name}
              price={item.price}
              bottomLine={!lastItem}
              verticalPadding={13}
            />
          );
        })}
      </div>

      <Divider color="#F2F1F6" height={3} style={{ marginTop: '10px', marginBottom: '10px' }} />

      <ReceiptItem name="Subtotal" price={roundedSubtotal} verticalPadding={5} />
      <ReceiptItem name="Tax" price={roundedTax} verticalPadding={5} />
      <ReceiptItem name={`Tip (${roundedTipPercent}%)`} price={roundedTip} verticalPadding={5} />
      <ReceiptItem name="Total" price={roundedTotal} verticalPadding={5} bold />

      {receipt.split_status === 2 && (
        <div className="receiptScreen-ctaButtonContainer">
          <button className="receiptScreen-ctaButton" onClick={() => viewSplitReceipt()}>
            View Split Receipt
          </button>
        </div>
      )}

      <DownloadAppFooter />
    </div>
  );
}
