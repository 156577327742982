import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '../images/logos/stitch_dark.png';
import CTAButton from './CTAButton';

export default function MenuTopBar() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="menuTopBar-container">
      <div className="menuTopBar-logoImageContainer" onClick={() => navigate('/')}>
        <img src={logo} className="menuTopBar-logoImage" />
      </div>
      <button
        className={
          location.pathname === '/' || location.pathname === '/merchants'
            ? 'menuTopBar-navOptionSelected'
            : 'menuTopBar-navOption'
        }
        onClick={() => {
          navigate('/merchants', { replace: true });
        }}
      >
        Merchants
      </button>
      <button
        className={
          location.pathname === '/users' ? 'menuTopBar-navOptionSelected' : 'menuTopBar-navOption'
        }
        onClick={() => {
          navigate('/users', { replace: true });
        }}
      >
        Users
      </button>
      <CTAButton
        className="menuTopBar-CTAButton"
        text="Contact Us"
        onClick={() => {
          navigate('/help');
        }}
      />
    </div>
  );
}
