import React, { useState } from 'react';
import amplitude from 'amplitude-js';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ReceiptItem from './ReceiptItem';
import Divider from '../../../components/Divider';
import DownloadAppFooter from './DownloadAppFooter';

import copyImage from '../../../images/copy_to_clipboard.png';

// TODO: fix iterator eslint errors
/* eslint-disable */
export default function ReceiptSplitDetails({ receipt, viewOriginalReceipt }) {
  const [copiedMember, setCopiedMember] = useState(undefined);
  const [memberToShouldDisplay, setMemberToShouldDisplay] = useState({});

  function round(price) {
    return (Math.round(price * 100) / 100).toFixed(2);
  }

  function copyTotal(member) {
    amplitude.getInstance().logEvent('copy_total');
    setCopiedMember(member);
  }

  function toggleShouldDisplay(member) {
    amplitude.getInstance().logEvent('toggle_show_details', {
      is_showing: !(memberToShouldDisplay[member] || false),
    });
    setMemberToShouldDisplay((memberToDisplay) => {
      const newMemberToDisplay = { ...memberToDisplay };
      const oldValue = newMemberToDisplay[member] || false;
      newMemberToDisplay[member] = !oldValue;
      return newMemberToDisplay;
    });
  }

  // UI for showing the splits
  const splitJsx = [];
  const itemIDToNumMembers = {};
  for (let i = 0; i < receipt.items.length; i++) {
    const item = receipt.items[i];
    let numMembers = 0;
    for (const member in receipt.member_to_item_ids || {}) {
      const currItemIDs = receipt.member_to_item_ids[member];
      if (currItemIDs.includes(item.id)) {
        numMembers += 1;
      }
    }
    itemIDToNumMembers[item.id] = numMembers;
  }

  // TODO: CALCULATE ACTUAL TIP AND TAX PERCENT
  let totalSubtotal = 0;
  for (const item of receipt.items) {
    totalSubtotal += item.price;
  }
  const tipPercent = receipt.tip / totalSubtotal;
  const taxPercent = receipt.tax / totalSubtotal;

  const memberUIDArray = Object.keys(receipt.member_to_item_ids || []);
  memberUIDArray.sort((a, b) => {
    if (receipt.uid_to_name[a] === receipt.uid_to_name[b]) return 0;
    return receipt.uid_to_name[a] < receipt.uid_to_name[b] ? -1 : 1;
  });
  for (const memberUID of memberUIDArray) {
    const memberJsx = [];
    let currSubtotal = 0;
    const currItemIDs = receipt.member_to_item_ids[memberUID];

    const memberItemsJsx = [];
    for (let i = 0; i < currItemIDs.length; i++) {
      const currItemID = currItemIDs[i];
      const currItem = receipt.items.find((item) => item.id === currItemID);
      if (currItem === undefined) {
        continue;
      }

      const numMembersWithItem = itemIDToNumMembers[currItemID];
      currSubtotal += currItem.price / numMembersWithItem;
      memberItemsJsx.push(
        <ReceiptItem
          name={`1/${numMembersWithItem} ${currItem.name}`}
          price={round(currItem.price / numMembersWithItem)}
          verticalPadding={2}
          horizontallyPadded
        />,
      );
    }

    const currTax = round(currSubtotal * taxPercent);
    const currTip = round(currSubtotal * tipPercent);
    const currTotal = round(currSubtotal * (1 + taxPercent + tipPercent));

    if (memberToShouldDisplay && memberToShouldDisplay[memberUID]) {
      memberJsx.push(memberItemsJsx);
      memberJsx.push(
        <div className="receiptScreen-verticalPadding">
          <Divider color="#F2F1F6" height={2} />
        </div>,
      );
      memberJsx.push(
        <ReceiptItem name="Subtotal" price={round(currSubtotal)} horizontallyPadded />,
      );
      memberJsx.push(<ReceiptItem name="Tax" price={currTax} horizontallyPadded />);
      if (receipt.tip !== undefined) {
        memberJsx.push(
          <ReceiptItem
            name={`Tip (${(tipPercent * 100).toFixed(0)}%)`}
            price={currTip}
            horizontallyPadded
          />,
        );
      }
      memberJsx.push(<ReceiptItem name="Total" price={currTotal} horizontallyPadded bold />);
    }
    memberJsx.unshift(
      <div className="receiptScreen-memberLineContainer">
        <div className="receiptScreen-nameAndTotalContainer">
          <div className="receiptScreen-nameAndTotalLabel">
            {receipt.uid_to_name[memberUID] || 'NameError'} &nbsp;|&nbsp; ${round(currTotal / 100)}
          </div>
          <CopyToClipboard text={currTotal / 100} onCopy={() => copyTotal(memberUID)}>
            <span>
              <img src={copyImage} className="receiptScreen-copyImage" />
            </span>
          </CopyToClipboard>
          {copiedMember === memberUID && <div className="receiptScreen-copiedLabel">Copied!</div>}
        </div>
        <div>
          {memberToShouldDisplay && memberToShouldDisplay[memberUID] ? (
            <button
              className="receiptScreen-detailsButton"
              onClick={() => toggleShouldDisplay(memberUID)}
            >
              Hide Details
            </button>
          ) : (
            <button
              className="receiptScreen-detailsButton"
              onClick={() => toggleShouldDisplay(memberUID)}
            >
              Show Details
            </button>
          )}
        </div>
      </div>,
    );
    splitJsx.push(
      <div className="receiptScreen-memberSplitContainer" key={memberUID}>
        {memberJsx}
      </div>,
    );
    splitJsx.push(<Divider color="#F2F1F6" height={4} />);
  }

  return (
    <div>
      <div className="receiptScreen-splitContainer">{splitJsx}</div>
      <div className="receiptScreen-ctaButtonContainer">
        <button className="receiptScreen-ctaButton" onClick={() => viewOriginalReceipt()}>
          View Original Receipt
        </button>
      </div>
      <DownloadAppFooter />
    </div>
  );
}
