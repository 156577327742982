import React, { useEffect, useState } from 'react';
import axios from 'axios';

import items from './constants/siamItems';
import TopBar from '../../components/TopBar';
import SelectItem from './components/SelectItem';

export default function VendorDemoScreen() {
  const [total, setTotal] = useState(0);
  const [screenIndex, setScreenIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setScreenIndex(0);
  }, []);

  useEffect(() => {
    setTotal(selectedItems.reduce((runningTotal, si) => runningTotal + items[si].price, 0));
  }, [selectedItems]);

  function incrementQuantity(index) {
    setSelectedItems((itemsArr) => [...itemsArr, index]);
  }

  function executeTransaction() {
    const body = {
      idempotency_key: Math.floor(Math.random() * 20).toString(),
      order: {
        reference_id: 'my-order-001',
        location_id: 'LCFSQTR6RS4ZA',
        taxes: [
          {
            uid: 'state-sales-tax',
            name: 'State Sales Tax',
            percentage: '9',
            scope: 'ORDER',
          },
        ],
      },
    };

    body.order.line_items = selectedItems.map((i) => ({
      name: items[i].name,
      quantity: '1',
      base_price_money: {
        amount: items[i].price,
        currency: 'USD',
      },
    }));

    axios
      .post('https://us-central1-stitch-c9ff8.cloudfunctions.net/createTestSquareTransaction', body)
      .then(() => {
        setSelectedItems([]);
        setScreenIndex(1);
      })
      .catch((error) => {
        setSelectedItems([]);
        console.error(error);
      });
  }

  return (
    <div>
      <TopBar />
      {screenIndex === 0 ? (
        <div className="demoScreen-container">
          <div className="demoScreen-itemSelectContainer">
            <div className="demoScreen-itemSelectGrid">
              {items.map((item, index) => (
                <SelectItem name={item.name} incrementQuantity={() => incrementQuantity(index)} />
              ))}
            </div>
          </div>
          <div className="demoScreen-checkoutContainer">
            <div className="demoScreen-checkoutTitle">
              Current sale (
              {selectedItems.length}
              )
            </div>
            {/* <div> */}
            {selectedItems.map((i) => (
              <div className="demoScreen-checkoutItem">
                <div className="demoScreen-checkoutItemTitle">{items[i].name}</div>
                <div>
                  $
                  {(Math.round(items[i].price) / 100).toFixed(2)}
                </div>
              </div>
            ))}
            {selectedItems.length > 0 ? (
              <div className="demoScreen-checkoutItem">
                <div>Tax</div>
                <div>
                  $
                  {(Math.round(0.09 * total) / 100).toFixed(2)}
                </div>
              </div>
            ) : null}
            {/* </div> */}
            {total !== 0 ? (
              <button
                className="demoScreen-executeTransactionButton"
                onClick={() => executeTransaction()}
              >
                Charge
                {' $'}
                {(Math.round(1.09 * total) / 100).toFixed(2)}
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
      {screenIndex === 1 ? (
        <div className="demoScreen-completedContainer">Transaction Successfully Completed</div>
      ) : null}
    </div>
  );
}
