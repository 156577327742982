import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useParams } from 'react-router-dom';
import amplitude from 'amplitude-js';

import TopBar from '../../components/TopBar';
import ReceiptHeader from './components/ReceiptHeader';
import ReceiptDetails from './components/ReceiptDetails';
import ChooseReceiptItems from './components/ChooseReceiptItems';
import ReceiptWait from './components/ReceiptWait';
import NameInput from './components/NameInput';
import ReceiptSplitDetails from './components/ReceiptSplitDetails';

export default function ReceiptScreen() {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [receipt, setReceipt] = useState(undefined);
  const [currentNameText, setCurrentNameText] = useState(undefined);
  const [currentName, setCurrentName] = useState(undefined);
  const [currentUID, setCurrentUID] = useState(undefined);
  const [isRepeatName, setIsRepeatName] = useState(false);
  const [selectedItemIDs, setSelectedItemIDs] = useState([]);
  const [isViewingOriginalReceipt, setIsViewingOriginalReceipt] = useState(undefined);
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  const { receiptID } = useParams();

  useEffect(() => fetchReceipt(), []); // eslint-disable-line

  useEffect(() => {
    // this useEffect is needed to log receipt status on load
    if (isFirstOpen && receipt) {
      amplitude.getInstance().logEvent('first_open', { split_status: receipt.split_status });
      setIsFirstOpen(false);
    }
  }, [receipt]);

  function fetchReceipt() {
    const db = firebase.firestore();
    const receiptRef = db.collection('receipts').doc(receiptID);

    return receiptRef.onSnapshot((doc) => {
      const newReceipt = doc.data();
      setReceipt(newReceipt);
    });
  }

  function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
      hash |= 0; // eslint-disable-line no-bitwise
    }
    return hash.toString();
  }

  function createName(bypassRepeat) {
    if (currentNameText === '') {
      return;
    }
    const hashedUID = hashCode(currentNameText.trim());

    if (!bypassRepeat && Object.keys(receipt.member_to_item_ids || {}).includes(hashedUID)) {
      setIsRepeatName(true);
      return;
    }
    amplitude.getInstance().logEvent('create_name');
    if (receipt.member_to_item_ids === undefined) {
      receipt.member_to_item_ids = {};
    }
    // TODO: if bypassRepeat, use name to find UID, and then use that
    const currentSelectedItemIDs = receipt.member_to_item_ids[hashedUID] || [];
    setSelectedItemIDs(currentSelectedItemIDs);
    setCurrentName(currentNameText);
    setCurrentUID(hashedUID);
  }

  function submitSelection() {
    amplitude.getInstance().logEvent('submit_selection', { num_items: selectedItemIDs.length });
    const db = firebase.firestore();

    const receiptRef = db.collection('receipts').doc(receiptID);
    const updatedDoc = {};
    updatedDoc[`member_to_item_ids.${currentUID}`] = selectedItemIDs;
    updatedDoc[`uid_to_name.${currentUID}`] = currentName;
    receiptRef.update(updatedDoc);
    setHasSubmitted(true);
  }

  function selectItem(itemID) {
    const selectType = selectedItemIDs.indexOf(itemID) === -1 ? 'select' : 'unselect';
    amplitude.getInstance().logEvent('select_item', { type: selectType });

    setSelectedItemIDs((selectedIDs) => {
      const newIDs = [...selectedIDs];
      const existingIndex = newIDs.indexOf(itemID);

      if (existingIndex !== -1) {
        newIDs.splice(existingIndex, 1);
      } else {
        newIDs.push(itemID);
      }
      return newIDs;
    });
  }

  function makeEdits() {
    amplitude.getInstance().logEvent('make_edits');
    setHasSubmitted(false);
  }

  function viewOriginalReceipt() {
    amplitude.getInstance().logEvent('toggle_view', { type: 'split_to_original' });
    setIsViewingOriginalReceipt(true);
  }

  function viewSplitReceipt() {
    amplitude.getInstance().logEvent('toggle_view', { type: 'original_to_split' });
    setIsViewingOriginalReceipt(false);
  }

  // Loading
  if (receipt === undefined) {
    return (
      <div>
        <TopBar />
        <div className="receiptScreen-instructionsContainer">
          <div className="receiptScreen-instructionsText">Loading...</div>
        </div>
      </div>
    );
  }

  // Not being split, just show receipt information
  if (receipt.split_status === 0 || isViewingOriginalReceipt) {
    return (
      <div>
        <TopBar />
        <ReceiptDetails receipt={receipt} viewSplitReceipt={() => viewSplitReceipt()} />
      </div>
    );
  }

  // Handle split logic
  return (
    <div>
      <TopBar />
      <ReceiptHeader receipt={receipt} subtitle="Split Receipt" />

      {currentName === undefined && receipt.split_status === 1 && (
        <NameInput
          onNameChange={setCurrentNameText}
          createName={(bypassRepeat) => createName(bypassRepeat)}
          isRepeatName={isRepeatName}
        />
      )}

      {currentUID !== undefined && !hasSubmitted && (
        <ChooseReceiptItems
          receipt={receipt}
          selectItem={(itemID) => selectItem(itemID)}
          selectedItemIDs={selectedItemIDs}
          submitSelection={() => submitSelection()}
          currentUID={currentUID}
        />
      )}

      {hasSubmitted && receipt.split_status === 1 && (
        <ReceiptWait receipt={receipt} makeEdits={() => makeEdits()} currentUID={currentUID} />
      )}

      {receipt.split_status === 2 && (
        <ReceiptSplitDetails receipt={receipt} viewOriginalReceipt={() => viewOriginalReceipt()} />
      )}
    </div>
  );
}
