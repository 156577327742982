import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../images/logos/stitch_light.png';
import sajjLogo from '../images/logos/stitch_sajj.png';

export default function TopBar({ name }) {
  const navigate = useNavigate();

  function getLogo() {
    if (name === 'sajj') {
      return sajjLogo;
    }
    return logo;
  }

  return (
    <div className="topBar-container">
      <div className="topBar-logoImageContainer" onClick={() => navigate('/')}>
        <img src={getLogo()} className="topBar-logoImage" />
      </div>
    </div>
  );
}
