import React, { useEffect, useRef, useState } from 'react';
import amplitude from 'amplitude-js';
import { useNavigate } from 'react-router-dom';

import CTAButton from '../../components/CTAButton';
import MenuTopBar from '../../components/MenuTopBar';

import MerchantMockup from '../../images/mockups/merchant_mockup.png';
import FeatureOne from '../../images/mockups/merchant_feature_detail_one.png';
import FeatureTwo from '../../images/mockups/merchant_feature_detail_two.png';
import FeatureThree from '../../images/mockups/merchant_feature_detail_three.png';
import ValueAddOne from '../../images/mockups/merchant_value_add_one.png';
import ValueAddTwo from '../../images/mockups/merchant_value_add_two.png';
import ValueAddThree from '../../images/mockups/merchant_value_add_three.png';
import ValueAddFour from '../../images/mockups/merchant_value_add_four.png';
import ValueAddFive from '../../images/mockups/merchant_value_add_five.png';
import ValueAddSix from '../../images/mockups/merchant_value_add_six.png';

function ValueAddContainer({ title, text, image }) {
  return (
    <div className="merchantLandingScreen-valueAddContainer">
      <div className="merchantLandingScreen-valueAddImageContainer">
        <img src={image} className="merchantLandingScreen-valueAddImage" />
      </div>
      <div className="merchantLandingScreen-valueAddTextBlock">
        <div className="merchantLandingScreen-valueAddTitle">{title}</div>
        <div className="merchantLandingScreen-valueAddText">{text}</div>
      </div>
    </div>
  );
}

export default function MerchantLandingScreen() {
  const navigate = useNavigate();
  const emailRef = useRef();

  const [email, setEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    amplitude.getInstance().logEvent('landing_page_open', { screen: 'merchant' });
  }, []);

  function submitEmail(text) {
    setShowError(false);
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(text)) {
      console.log('success email');
      amplitude.getInstance().logEvent('email_waitlist_merchant', { value: email });
      setOldEmail(email);
      setEmail('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } else {
      console.log('setError');
      setShowError(true);
    }
  }

  function focusEmail() {
    emailRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      emailRef.current.focus();
    }, 1000);
  }

  return (
    <div className="landingScreen-container">
      <MenuTopBar />
      <div className="merchantLandingScreen-header">
        <div className="landingScreen-headerContentContainer">
          <div className="landingScreen-headerTextBlock">
            <div className="landingScreen-headerTitle">Grow Your Business with Receipts 3.0</div>
            <div className="landingScreen-headerText">
              Directly engage your customers
              {' '}
              <b>
                <i>without</i>
              </b>
              {' '}
              paper, email or phone
            </div>
            <button
              className="landingScreen-ctaButton"
              onClick={() => {
                focusEmail();
              }}
            >
              Get started for free
            </button>
          </div>
          <div className="merchantLandingScreen-headerMockupContainer">
            <img src={MerchantMockup} className="merchantLandingScreen-headerMockup" />
          </div>
        </div>
      </div>
      <div className="merchantLandingScreen-featuresContainer">
        <div className="merchantLandingScreen-featureContainer">
          <div className="merchantLandingScreen-featureTextBlock">
            <div className="merchantLandingScreen-featureTitle">
              Save Thousands On Receipt Paper
            </div>
            <div className="merchantLandingScreen-featureText">
              Receipts go directly your customers phone, without requiring their email or phone
              number. So you don’t need to buy more receipt paper for good.
            </div>
          </div>
          <div className="merchantLandingScreen-featureMockupContainer">
            <img src={FeatureOne} className="merchantLandingScreen-featureMockup" />
          </div>
        </div>

        <div className="merchantLandingScreen-featureContainerReverse">
          <div className="merchantLandingScreen-featureTextBlockReverse">
            <div className="merchantLandingScreen-featureTitle">
              Directly Engage With Your Customers
            </div>
            <div className="merchantLandingScreen-featureText">
              Every customer needs a receipt, so you can engage with every person, through coupons,
              loyalty programs, app marketing and much more.
            </div>
          </div>
          <div className="merchantLandingScreen-featureMockupContainerReverse">
            <img src={FeatureTwo} className="merchantLandingScreen-featureMockup" />
          </div>
        </div>

        <div className="merchantLandingScreen-featureContainer">
          <div className="merchantLandingScreen-featureTextBlock">
            <div className="merchantLandingScreen-featureTitle">
              Gain Unique Insights From Purchase Data
            </div>
            <div className="merchantLandingScreen-featureText">
              Gain access to unprecedented data analytics on your users and sales to help tailor
              your business to your customer’s preferences.
            </div>
          </div>
          <div className="merchantLandingScreen-featureMockupContainer">
            <img src={FeatureThree} className="merchantLandingScreen-featureMockup" />
          </div>
        </div>
      </div>

      <div className="merchantLandingScreen-valueAddsContainer">
        <div className="merchantLandingScreen-valueAddHeader">
          <div className="merchantLandingScreen-valueAddHeaderSpan" />
          <div className="merchantLandingScreen-valueAddHeaderText">OTHER BENEFITS</div>
          <div className="merchantLandingScreen-valueAddHeaderSpan" />
        </div>
        <div className="merchantLandingScreen-valueAddsGrid">
          <ValueAddContainer
            title="Save millions of trees"
            text="Switch to digital receipts to save the environment and reduce CO2 emissions"
            image={ValueAddOne}
          />
          <ValueAddContainer
            title="Boost your retention"
            text="Strategically place limited time coupons or low friction loyalty programs to bring customers back"
            image={ValueAddTwo}
          />
          <ValueAddContainer
            title="Engage with every customer"
            text="Everyone needs a receipt, so you can engage with every customer without their email or ads"
            image={ValueAddThree}
          />
          <ValueAddContainer
            title="Communicate without spamming"
            text="Communicate deals, loyalty programs, and promos without spamming emails or phones"
            image={ValueAddFour}
          />
          <ValueAddContainer
            title="Customize content on receipts"
            text="Instantly customize your Stitch receipts to fit your design preferences and immediate needs"
            image={ValueAddFive}
          />
          <ValueAddContainer
            title="Integrate with any POS"
            text="Stitch device integrates with every POS system, just like a normal receipt printer"
            image={ValueAddSix}
          />
        </div>
      </div>

      <div className="landingScreen-footer">
        <div className="landingScreen-footerTitle">Say goodbye to paper receipts</div>
        <div className="landingScreen-footerSubtitle">
          Get started for
          {' '}
          <i>completely free</i>
        </div>
        <div className="landingScreen-footerEmail">
          <div>
            <input
              ref={emailRef}
              className="landingScreen-footerEmailInput"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              onFocus={(e) => {
                if (e.target.value.trim().length !== 0) e.target.select();
              }}
            />
          </div>
          <CTAButton
            text="Sign up"
            type="solid"
            onClick={() => {
              submitEmail(email);
            }}
          />
        </div>

        {showSuccess ? (
          <div className="landingScreen-footerSuccess">
            Thanks for your interest! An email will be sent to
            {' '}
            {oldEmail}
            {' '}
            regarding our beta.
          </div>
        ) : null}
        {showError ? (
          <div className="landingScreen-footerError">Please enter a valid email</div>
        ) : null}
        {!showSuccess && !showError ? (
          <div className="landingScreen-footerSuccess">&nbsp;</div>
        ) : null}

        <div className="landingScreen-legalContainer">
          <div className="landingScreen-copyright">© 2022 Stitch Finance Inc.</div>
          <div className="landingScreen-innerLegalContainer">
            <button
              className="landingScreen-legalLinks"
              onClick={() => navigate('/help', { replace: false })}
            >
              contact us
            </button>
            <a className="landingScreen-legalLinks" href="/terms.pdf">
              terms
            </a>
            <a className="landingScreen-legalLinks" href="/privacypolicy.pdf">
              privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
