import React from 'react';
import amplitude from 'amplitude-js';

export default function DownloadAppFooter() {
  // Adding padding above footer to account for footer
  return (
    <div>
      <div style={{ height: '80px' }} />
      <div className="receiptScreen-downloadAppContainer">
        <div className="receiptScreen-downloadAppText">Want to save this receipt?</div>
        <a
          className="receiptScreen-textButton"
          href="https://apps.apple.com/us/app/stitch-split-bill/id1632674016"
          onClick={() => amplitude.getInstance().logEvent('download_app_press')}
        >
          Download the app.
        </a>
      </div>
    </div>
  );
}
