import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { useNavigate, useParams } from 'react-router-dom';

import TopBar from '../../components/TopBar';

export default function StaticDeviceScreen() {
  const navigate = useNavigate();
  const { deviceID } = useParams();

  // TODO: figure out useEffect() is called twice
  useEffect(() => {
    firebase
      .database()
      .ref(`device_to_receipt/${deviceID}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const receiptID = snapshot.val();
          navigate(`../${receiptID}`, { replace: true });
        } else {
          console.log('No receipt available');
        }
      });
  }, []);

  return (
    <div>
      <TopBar />
      <div className="receiptScreen-instructionsContainer">
        <div className="receiptScreen-instructionsText">Loading...</div>
      </div>
    </div>
  );
}
