import React from 'react';

export default function NameInput({ onNameChange, createName, isRepeatName }) {
  return (
    <div className="receiptScreen-nameInputContainer">
      <div className="receiptScreen-subTitleText">First Name</div>
      <input
        className="receiptScreen-nameInput"
        key="input"
        type="text"
        onChange={(e) => onNameChange(e.target.value.trim())}
      />
      <button className="receiptScreen-ctaButton" onClick={() => createName()}>
        Continue
      </button>
      {isRepeatName && (
        <div className="receiptScreen-repeatNameContainer">
          <div className="receiptScreen-errorText">
            This name is already in use. Please choose another.
          </div>
          <button className="receiptScreen-repeatNameButton" onClick={() => createName(true)}>
            Is this you? Tap here.
          </button>
        </div>
      )}
    </div>
  );
}
