import React from 'react';

export default function CTAButton(props) {
  const type = props.type || 'outline';
  return (
    <button
      className={`ctaButton-${type}${props.className ? ` ${props.className}` : ''}`}
      onClick={() => props.onClick()}
    >
      {props.text}
    </button>
  );
}
