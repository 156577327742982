import React from 'react';

function Divider({
  color, height, marginLeft, style,
}) {
  return (
    <hr
      style={{
        color,
        backgroundColor: color,
        height,
        borderWidth: 0,
        margin: '0px',
        marginLeft: marginLeft || '0px',
        ...style,
      }}
    />
  );
}

export default Divider;
