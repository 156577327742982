import React from 'react';

export default function ReceiptHeader({ receipt, subtitle }) {
  const receiptDate = new Date(receipt.date * 1000);

  return (
    <div className="receiptScreen-header">
      <div className="receiptScreen-grayText">{receiptDate.monthDayYear()}</div>
      <div className="receiptScreen-titleText">{receipt.vendor}</div>
      {subtitle && <div className="receiptScreen-grayText">Split Receipt</div>}
    </div>
  );
}

Date.prototype.monthDayYear = function () {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthString = monthNames[this.getMonth()]; // getMonth() is zero-based
  const dayOfMonth = this.getDate();

  return `${monthString} ${dayOfMonth}, ${this.getFullYear()}`;
};
