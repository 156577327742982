import React from 'react';

export default function FeatureDetail(props) {
  return (
    <div className="featureDetail-container">
      <img src={props.img} className="featureDetail-image" />
      <div className="featureDetail-title">{props.title}</div>
      <div className="featureDetail-text">{props.text}</div>
    </div>
  );
}
