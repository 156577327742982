import React from 'react';

export default function ReceiptWait({ receipt, makeEdits, currentUID }) {
  // UI for showing submitted members
  const submittedMemberUIDs = Object.keys(receipt.member_to_item_ids || {});
  submittedMemberUIDs.sort((a, b) => {
    if (receipt.uid_to_name[a] === receipt.uid_to_name[b]) return 0;
    return receipt.uid_to_name[a] < receipt.uid_to_name[b] ? -1 : 1;
  });
  if (submittedMemberUIDs.includes(currentUID)) {
    const index = submittedMemberUIDs.indexOf(currentUID);
    submittedMemberUIDs.splice(index, 1);
    submittedMemberUIDs.unshift(currentUID);
  }

  return (
    <div>
      <div className="receiptScreen-instructionsContainer">
        <div className="receiptScreen-instructionsText">Waiting for receipt to be closed...</div>
        <button
          className="receiptScreen-repeatNameButton"
          style={{ marginTop: '10px', marginBottom: '20px' }}
          onClick={() => makeEdits()}
        >
          Want to make edits?
        </button>
      </div>
      <div className="receiptScreen-submittedContainer">
        <div className="receiptScreen-instructionsText">Submitted</div>
        <div>
          {submittedMemberUIDs.map((memberUID) => (
            <div className="receiptScreen-submittedMemberLabel">
              {memberUID === currentUID ? 'You' : receipt.uid_to_name[memberUID] || 'NameError'}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
