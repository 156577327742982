import React, { useState } from 'react';
import amplitude from 'amplitude-js';

import TopBar from '../../components/TopBar';

import CTAButton from '../../components/CTAButton';
import { checkEmailFormat } from '../../utils/utils';

export default function HelpScreen() {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  function submitFeedback() {
    setShowError(false);
    if (checkEmailFormat(email)) {
      amplitude.getInstance().logEvent('submit_feedback', { email, feedback });
      setEmail('');
      setFeedback('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } else {
      console.log('setError');
      setShowError(true);
    }
  }

  return (
    <div>
      <TopBar />
      <div className="helpScreen-container">
        <div className="helpScreen-inputContainer">
          <div className="helpScreen-inputTitle">Email</div>
          <input
            className="helpScreen-input"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            onFocus={(e) => {
              if (e.target.value.trim().length !== 0) e.target.select();
            }}
          />
        </div>
        <div className="helpScreen-inputContainer">
          <div className="helpScreen-inputTitle">Question / Feedback</div>
          <textarea
            className="helpScreen-inputLarge"
            value={feedback}
            type="textarea"
            onChange={(e) => setFeedback(e.target.value)}
            onFocus={(e) => {
              if (e.target.value.trim().length !== 0) e.target.select();
            }}
            rows={5}
          />
        </div>
        <div className="helpScreen-button">
          <CTAButton text="Submit" type="solid" onClick={() => submitFeedback()} />
        </div>

        {showSuccess ? (
          <div className="helpScreen-success">
            Thanks for your feedback! We&apos;ll get back to you as soon as we can.
          </div>
        ) : null}
        {showError ? <div className="helpScreen-error">Please enter a valid email</div> : null}
        {!showSuccess && !showError ? <div className="helpScreen-success">&nbsp;</div> : null}

        <div>
          <div className="helpScreen-inputTitle">Contact Information</div>
          <div>arvindvs@stitchfinance.com</div>
        </div>
      </div>
    </div>
  );
}
