import React from 'react';

import Divider from '../../../components/Divider';

export default function ReceiptItem({
  name,
  price,
  bottomLine,
  verticalPadding,
  horizontallyPadded,
  bold,
}) {
  return (
    <div>
      <div
        className="receiptScreen-itemContainer"
        style={{ paddingTop: verticalPadding, paddingBottom: verticalPadding }}
      >
        <div
          className={
            horizontallyPadded
              ? 'receiptScreen-memberLineContainer'
              : 'receiptScreen-itemPriceContainer'
          }
        >
          <div className="receiptScreen-itemLabel" style={bold && { 'font-weight': 'bold' }}>
            {name}
          </div>
          <div className="receiptScreen-priceLabel" style={bold && { 'font-weight': 'bold' }}>
            $
            {(Math.round(price) / 100).toFixed(2)}
          </div>
        </div>
      </div>
      {bottomLine && <Divider color="#F2F1F6" height={1} marginLeft="30px" />}
    </div>
  );
}
